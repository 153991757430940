import React from "react";
import {
  Heading,
  Button,
  Card,
  CardHeader,
  CardBody,
  Text,
  useDisclosure,
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  Stack,
} from "@chakra-ui/react";
import { accordionStates, buttonStates, colours, sizes } from "./themes";

const PortfolioBox = (props) => {
  const { isOpen, onOpen, onClose } = useDisclosure();

  return (
    <>
      <Card
        onClick={onOpen}
        bg={colours.altColour}
        color={colours.text}
        maxW={220}
        h={220}
        _hover={buttonStates.onHover}
        _active={buttonStates.onActive}
        _focus={buttonStates.onFocus}
      >
        <CardHeader mb={-5}>
          <Heading size="md" as="h4" textAlign="center">
            {props.title}
          </Heading>
          <Text textAlign="center" fontSize="lg">
            ({props.status})
          </Text>
        </CardHeader>
        <CardBody>
          <Text fontSize="lg">{props.shortDescription}</Text>
        </CardBody>
      </Card>
      <Modal
        onClose={onClose}
        isOpen={isOpen}
        isCentered
        maxW={sizes.maxWidth}
        blockScrollOnMount={false}
      >
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>{props.title}</ModalHeader>
          <ModalCloseButton />
          <ModalBody>
            <Stack spacing={5}>
              <Text>Status: {props.status}</Text>
              {props.tech ? (
                <Text>Utilises: {props.tech.join(", ")}</Text>
              ) : (
                <></>
              )}
              <Stack spacing={2}>
                {props.text.map((textBlock) => {
                  return <Text>{textBlock}</Text>;
                })}
              </Stack>
            </Stack>
          </ModalBody>
          <ModalFooter>
            {props.link ? (
              <Button
                onClick={() => window.open(props.link, "_blank").focus()}
                backgroundColor={colours.altColour}
                _hover={accordionStates.onHover}
                mr={3}
              >
                <Text
                  textAlign="center"
                  textTransform="uppercase"
                  color={colours.buttonText}
                >
                  {props.linkText}
                </Text>
              </Button>
            ) : (
              <></>
            )}
            <Button
              onClick={onClose}
              textTransform="uppercase"
              backgroundColor={colours.darkColour}
              color={colours.buttonText}
              _hover={{ backgroundColor: colours.grey }}
            >
              Close
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </>
  );
};

export default PortfolioBox;
